import React, { useEffect, useState } from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/layout.css"

function IndexPage() {
  const [email1, setEmail1] = useState("");
  const [email2, setEmail2] = useState("");
  const [email3, setEmail3] = useState("");
  const [email4, setEmail4] = useState("");

  useEffect(() => {
    setEmail1("simon");
    setEmail2("@")
    setEmail3("scgough")
    setEmail4(".co.uk")
  }, []);

  return (
    <Layout>
      <SEO title="Software Developer from the UK" description="I am a UK based developer specialising in React-Native and .NET (dotnet) with over 20 years experience building apps for the web and mobile devices." />
      <h1 style={{marginBottom:30}}>I am a UK based software developer with over 20 years commercial experience.</h1>
      <h3>I like to build mobile applications in React-Native and have also worked extensively with .NET on the web.</h3>
      <h3>Right now I'm part of the amazing team at <a target="_blank" rel="noreferrer" className="workLink" href="https://www.pexels.com"><span role="img">📸</span> Pexels.com</a> (w/ Canva) developing their mobile application.</h3>
      <hr />
      <h3 style={{mxarginBottom:10}}>Links:</h3>
      <p><OutboundLink 
      href="https://twitter.com/scgough" target="_blank" rel="noreferrer">Twitter</OutboundLink> / <OutboundLink 
      href="https://www.linkedin.com/in/scgough/" target="_blank" rel="noreferrer">LinkedIn</OutboundLink> / <OutboundLink 
      href="https://www.pexels.com/@scgough" target="_blank" rel="noreferrer">Pexels</OutboundLink> / <OutboundLink 
      href={`mailto:${email1}${email2}${email3}${email4}`}>Email</OutboundLink></p>
    </Layout>
  )
}

export default IndexPage
